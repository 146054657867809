import React from 'react';
import PropTypes from 'prop-types';

import { Slide } from 'pure-react-carousel';
import { Card } from 'semantic-ui-react';

const SeriesCardSlide = ({ index, ...cardProps }) => (
    <Slide index={index}>
        <div style={{ padding: 10 }}>
            <Card fluid {...cardProps} />
        </div>
    </Slide>
);

SeriesCardSlide.propTypes = {
    index: PropTypes.number.isRequired,
};

export default SeriesCardSlide;
