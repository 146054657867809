import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Grid, Menu } from 'semantic-ui-react';
import { kebabCase } from 'lodash';

import CardCarousel from 'src/components/shared/CardCarousel';
import SeriesCardSlide from 'src/components/shared/SeriesCardSlide';

import myPlanStyles from 'src/styles/my-plan.module.scss';

const MyPlanJoinSeries = ({ onboarding }) => {
    const [activeItem, setActiveItem] = useState('all-series');

    const handleItemClick = (e, { name }) => setActiveItem(kebabCase(name));

    return (
        <Grid relaxed>
            <Grid.Row className={myPlanStyles.pageSection}>
                <Grid.Column>
                    <div className={myPlanStyles.sectionHeader}>
                        {onboarding ? (
                            <h2>Step 2: Join a Series</h2>
                        ) : (
                            <h2>Pick your next series</h2>
                        )}
                        <p>
                            These expertly designed structured programs maximize
                            your results and help you stay committed to the
                            method.
                        </p>
                    </div>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column width={6}>
                                <Menu pointing secondary>
                                    <Menu.Item
                                        name="All Series"
                                        active={activeItem === 'all-series'}
                                        onClick={handleItemClick}
                                    />
                                    <Menu.Item
                                        name="Foundation"
                                        active={activeItem === 'foundation'}
                                        onClick={handleItemClick}
                                    />
                                    <Menu.Item
                                        name="A Week or Less"
                                        active={activeItem === 'a-week-or-less'}
                                        onClick={handleItemClick}
                                    />
                                    <Menu.Item
                                        name="A Week Plus"
                                        active={activeItem === 'a-week-plus'}
                                        onClick={handleItemClick}
                                    />
                                </Menu>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column
                                width={4}
                                verticalAlign="middle"
                                textAlign="center"
                            >
                                <h3>Workout Series</h3>
                                <Button fluid basic>
                                    See all 18 Series
                                </Button>
                            </Grid.Column>
                            <Grid.Column width={12}>
                                <CardCarousel totalSlides={5} visibleSlides={3}>
                                    <SeriesCardSlide
                                        image="https://place-hold.it/800x800&text=Video&fontsize=32"
                                        header="P.Stance and P.sit 1"
                                        meta="Full body - Foundation"
                                        index={0}
                                    />
                                    <SeriesCardSlide
                                        header="P.Stance and P.sit 2"
                                        image="https://place-hold.it/800x800&text=Video&fontsize=32"
                                        meta="Full body - Foundation"
                                        index={1}
                                    />
                                    <SeriesCardSlide
                                        header="P.Stance and P.sit 3"
                                        image="https://place-hold.it/800x800&text=Video&fontsize=32"
                                        meta="Full body - Foundation"
                                        index={2}
                                    />
                                    <SeriesCardSlide
                                        header="P.Stance and P.sit 4"
                                        image="https://place-hold.it/800x800&text=Video&fontsize=32"
                                        meta="Full body - Foundation"
                                        index={2}
                                    />
                                    <SeriesCardSlide
                                        header="P.Stance and P.sit 5"
                                        image="https://place-hold.it/800x800&text=Video&fontsize=32"
                                        meta="Full body - Foundation"
                                        index={2}
                                    />
                                </CardCarousel>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

MyPlanJoinSeries.propTypes = {
    onboarding: PropTypes.bool,
};

MyPlanJoinSeries.defaultProps = {
    onboarding: false,
};

export default MyPlanJoinSeries;
