import React from 'react';
import { Icon, Grid, Header } from 'semantic-ui-react';
import { Spinner } from 'react-activity';
import { getDay } from 'date-fns';
import classNames from 'classnames';

import myPlanStyles from 'src/styles/my-plan.module.scss';

const DAYS = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
];
const TodaysIndex = getDay(new Date()) - 1;

const ThisWeek = ({ workouts, currentSeries, ready }) => {
    const day = (weekday, index) => {
        const isToday = index === TodaysIndex;
        return (
            <div key={index} className={classNames(myPlanStyles.weekday)}>
                {isToday && <p className={myPlanStyles.today}>today</p>}
                <p className={myPlanStyles.small}>{weekday}</p>
                <Icon
                    className={myPlanStyles.weekdayIcon}
                    color="teal"
                    circular
                    inverted
                    name="check"
                />
            </div>
        );
    };

    if (!ready) {
        return <Spinner size={40} speed={0.75} />;
    }

    return (
        <Grid className={myPlanStyles.thisWeek}>
            <Header as="h1">This Week</Header>
            <Grid.Row columns={10}>{DAYS.map(day)}</Grid.Row>
        </Grid>
    );
};

export default ThisWeek;
