/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable jsx-a11y/control-has-associated-label*/
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import videojs from 'video.js';

import { isValidMuxUrl } from 'src/utils/url-utils';

import 'video.js/dist/video-js.css';
import 'src/styles/video-player.scss';

// video.js player from the docs: https://github.com/videojs/video.js/blob/master/docs/guides/react.md
const PreviewVideoPlayer = ({ src, options, onClick }) => {
    let player;

    const videoId = `vjs-preview-player`;

    /**
     * Video.js has a fluid mode that keeps the player sized to a particular aspect ratio.
     * By default, fluid mode will use the intrinsic size of the video once loaded but you
     * can change it with classes or with the aspectRatio option.
     */
    const defaultOptions = {
        autoplay: true,
        muted: true,
        controls: false,
        fluid: true,
        loop: true,
        aspectRatio: '16:9',
        preload: 'auto',
        src: src,
        html5: {
            hls: {
                enableLowInitialPlayist: true,
                smoothQualityChange: true,
            },
        },
    };

    useEffect(() => {
        // componentDidMount
        createPlayer();

        return () => {
            // componentDidUnmount
            disposePlayer();
        };
    }, []);

    useEffect(() => {
        if (player && isValidMuxUrl(src)) {
            player.src(src);
        }
    }, [src]);

    const createPlayer = () => {
        if (player || !isValidMuxUrl(src)) {
            return;
        }

        initPlayer();
        initPlayerUI();
        initPlayerEvents();
    };

    const disposePlayer = () => {
        if (player) {
            player.dispose();
        }
    };

    const initPlayer = () => {
        // instantiate Video.js player using the id of the <video> element
        if (document.getElementById(videoId)) {
            player = videojs(videoId, {
                ...defaultOptions,
                ...options,
                sources: [src],
            });
        }
    };

    const initPlayerUI = () => {
        if (!player) {
            return;
        }

        /**
         * Get video.js components / component classes
         * @see https://docs.videojs.com/tutorial-components.html
         * @see https://docs.videojs.com/component
         */
        const BigPlayButton = videojs.getComponent('BigPlayButton');

        /**
         * Remove the default Video.js UI components
         */
        player.removeChild('LoadingSpinner');
        player.removeChild('BigPlayButton');
        player.removeChild('TextTrackSettings');
        player.removeChild('TextTrackDisplay');
        player.removeChild('ControlBar');

        /**
         * Create a custom BigPlayButton component
         */
        const PreviewBigPlayButton = videojs.extend(BigPlayButton, {
            constructor: function () {
                BigPlayButton.apply(this, arguments);
                this.controlText('Preview');
            },
            handleClick: function () {
                onClick(src);
            },
        });

        videojs.registerComponent('PreviewBigPlayButton', PreviewBigPlayButton);

        player.addChild('PreviewBigPlayButton');

        /**
         * Create a custom ModalDialog component
         */
        const ModalDialog = videojs.getComponent('ModalDialog');
        const previewModal = new ModalDialog(player, {
            content: '',
            description: 'Preview video modal overlay',
            label: 'Preview video modal overlay',
            uncloseable: true,
            pauseOnOpen: false,
        });

        player.addChild(previewModal);

        /**
         * Open the preview Modal
         */
        previewModal.open();
    };

    const initPlayerEvents = () => {
        /**
         * ToDo: Does the inline preview need analytics?
         */
    };

    /**
     * Wrap the player in a div with a `data-vjs-player` attribute
     * and videojs will re-use that div for the player element and
     * that video element for the tech.
     *
     * @see https://github.com/videojs/video.js/pull/3856
     * @see https://github.com/videojs/video.js/issues/3816#issuecomment-268907728
     **/
    const videoHtml = `
        <div data-vjs-player>
            <video id="${videoId}" class="video-js"></video>
        </div>
    `;

    return (
        <div
            className="preview-video-container"
            dangerouslySetInnerHTML={{ __html: videoHtml }}
        ></div>
    );
};

PreviewVideoPlayer.propTypes = {
    src: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    options: PropTypes.object,
    events: PropTypes.object,
};

PreviewVideoPlayer.defaultProps = {
    options: {},
    events: {},
};

export default PreviewVideoPlayer;
