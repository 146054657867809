import React, { useState, useEffect } from 'react';
import queryString from 'query-string';
import { graphql } from 'gatsby';
import { connect } from 'react-redux';

import SEO from 'src/components/SEO';
import VideoPlayer from 'src/components/shared/VideoPlayer';
import MyPlanOnboarding from 'src/components/workout/MyPlanOnboarding';
import MyPlanJoinSeries from 'src/components/workout/MyPlanJoinSeries';
import MyPlanTodaysWorkout from 'src/components/workout/MyPlanTodaysWorkout';
import ThisWeek from 'src/components/workout/ThisWeek';
import Selectors from 'src/state/root-selectors';

import { redirect } from 'src/utils/url-utils';

import myPlanStyles from 'src/styles/my-plan.module.scss';

const MyPlanPage = ({ loggedIn, location, data }) => {
    const {
        contentfulSeries: {
            contentful_id,
            name,
            slug,
            equipment,
            bodyFocus,
            thumbnail,
            workout,
        },
    } = data;

    /**
     * If a new user is within their first 7 sessions,
     * then we show them the onboarding steps.
     *
     * Otherwise, we show them the Today's Workout Module
     *
     * TBD: What does '7 sessions' mean?
     *
     * ToDo: get this value from SDK
     */
    const { isOnboarding } = queryString.parse(location.search);

    const [videoSrc, setVideoSrc] = useState();
    const [entitled, setEntitled] = useState(false);

    const HOW_IT_WORKS_URL = 'https://www.pvolve.com/pages/stream-your-workout';

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        // TODO: once we have entitlements built, we need to redirect
        // if the user is logged in but not entitled
        if (window && !loggedIn) {
            redirect(HOW_IT_WORKS_URL);
        } else {
            setEntitled(true);
        }
    }, []);

    const openVideoPlayer = (source) => {
        setVideoSrc(source);
    };

    const closeVideoPlayer = () => {
        setVideoSrc(null);
    };

    const playerOptions = {
        /**
         * Optional: Any additional VideoJs options
         * Will override defaults in VideoPlayer.js
         */
    };

    /**
     * Optional: any additional event handling
     * Event analytics are handled inside VideoPlayer.js
     *
     * In this example, we are using `onClose`
     * to update page-level video state.
     */
    const playerEvents = {
        onClose: closeVideoPlayer,
        onPlay: () => console.log('onPlay'),
        onPause: () => console.log('onPause'),
    };

    if (!entitled) {
        return null;
    }

    return (
        <div className={myPlanStyles.myPlanContainer}>
            <SEO title="Workouts: My Plan" />
            {isOnboarding ? (
                <MyPlanOnboarding
                    series={data.contentfulSeries}
                    workouts={workout}
                    onVideoClick={openVideoPlayer}
                />
            ) : (
                <MyPlanTodaysWorkout onPreviewVideoClick={openVideoPlayer} />
            )}
            <MyPlanJoinSeries onboarding={!!isOnboarding} />
            {/**
             * TODO: The thisWeek component is not wired together to the Workout History SDK
             * `workout` prop is just a placeholder. It should be the workout history service workouts,
             * not the contentful series workouts.
             **/}
            <ThisWeek workouts={workout} ready={true} />
            <VideoPlayer
                src={videoSrc}
                options={playerOptions}
                events={playerEvents}
            />
        </div>
    );
};

/**
 * TODO: `157960` is the current purpleId for the Summit 60 Series
 * There is no Foundation `Series` in Contentful, yet.
 * Once available, swap out.
 */
export const query = graphql`
    query foundationSeriesQuery {
        contentfulSeries(purpleId: { eq: 157960 }) {
            contentful_id
            name
            slug
            equipment {
                name
            }
            bodyFocus {
                name
            }
            benefit {
                name
            }
            thumbnail {
                file {
                    url
                }
                fixed(height: 300, width: 300) {
                    srcWebp
                }
            }
            workout {
                contentful_id
                name
                length
                thumbnail {
                    fixed {
                        srcWebp
                    }
                }
                equipment {
                    name
                }
                bodyFocus {
                    name
                }
            }
        }
    }
`;

const mapStateToProps = (state) => ({
    loggedIn: Selectors.auth.loggedIn(state),
});

export default connect(mapStateToProps)(MyPlanPage);
