import React from 'react';
import { Grid, Image } from 'semantic-ui-react';

import SeriesCarousel from 'src/components/shared/SeriesCarousel';

import 'react-activity/dist/react-activity.css';
import myPlanStyles from 'src/styles/my-plan.module.scss';
import celebration from 'src/assets/celebration.png';

const MyPlanOnboarding = ({ series, onVideoClick }) => {
    return (
        <Grid relaxed>
            <Grid.Row className={myPlanStyles.pageSection} centered>
                <Grid.Column width={2} verticalAlign="middle">
                    <Image
                        src={celebration}
                        alt="Celebration icon"
                        width="80"
                        height="80"
                    />
                </Grid.Column>
                <Grid.Column width={5}>
                    <h1>Welcome to the team!</h1>
                    <p>Committing to yourself is huge.</p>
                    <p>We're so happy to be on this journey with you.</p>
                    <p>Let's get started...</p>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row
                className={`${myPlanStyles.pageSection} ${myPlanStyles.backgroundBox}`}
            >
                <Grid.Column>
                    <div className={myPlanStyles.sectionHeader}>
                        <h2>Step 1: Meet the Method</h2>
                        <p>
                            Try these {series?.workouts?.length}, quick
                            foundational moves to start mastering your form.
                        </p>
                    </div>
                    <SeriesCarousel
                        series={series}
                        onVideoClick={onVideoClick}
                    />
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

export default MyPlanOnboarding;
