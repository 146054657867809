import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { Link } from 'gatsby';
import { Button, Grid, Icon, Label } from 'semantic-ui-react';

import PreviewVideoPlayer from 'src/components/shared/PreviewVideoPlayer';
import myPlanStyles from 'src/styles/my-plan.module.scss';

const MyPlanTodaysWorkout = ({ onPreviewVideoClick }) => {
    let todaysWorkout;

    // TODO: get this value from SDK
    const hasJoinedSeries = false;

    if (hasJoinedSeries) {
        // TODO: use series data to determine todays workout
    } else {
        // User has not joined series
        // So today's workout is going to be the newest workout

        // TODO: get newest workout from SDK
        const newestWorkout = {
            src:
                'https://stream.mux.com/9eDR01apdOrQQL00hMmMbZBuFcSCASAqwiooZYdbQAi7s.m3u8',
            name: '24 Minute Standing Glider Workout',
            description:
                'This session is amazing! P demonstrates that his unique moves and stepping patterns can work our body without equipment. Keeping everything slow and controlled, while focusing on perfect form allows us to feel our muscles and tone our body. Lifting our leg at different angles and heights works the outer hips, the inner thighs and glutes. Leg dips and lifts work the stomach, the sides and the top of the thighs. Stepping patterns create strong muscles for more functional movement. Concentrating on the stretch, you will notice your body being more flexible and fluid.',
            focus: ['Butt/Thighs', 'Abs'],
            type: 'Foundation',
            equipment: 'Glider',
            numFavorites: 13,
            slug: 'tighten-up-with-no-equipment',
        };

        todaysWorkout = newestWorkout;
    }

    const {
        src,
        name,
        description,
        focus,
        type,
        equipment,
        numFavorites,
        slug,
    } = todaysWorkout;

    return (
        <Grid relaxed>
            <Grid.Row
                className={`${myPlanStyles.pageSection} ${myPlanStyles.secondary}`}
            >
                <Grid.Column width={9}>
                    <PreviewVideoPlayer
                        src={src}
                        onClick={onPreviewVideoClick}
                    />
                </Grid.Column>
                <Grid.Column width={7}>
                    <h2>Our Newest Workout</h2>
                    <h3>{name}</h3>
                    <p>
                        {description}
                        <Link to={`/workouts/${slug}`}>See Details</Link>
                    </p>
                    <ul className="margin-top--small">
                        {!_.isEmpty(focus) && (
                            <li>
                                <span className="secondaryText">Focus:</span>{' '}
                                {_(focus).toString()}
                            </li>
                        )}
                        {!_.isEmpty(type) && (
                            <li>
                                <span className="secondaryText">Type:</span>{' '}
                                {type}
                            </li>
                        )}
                        {!_.isEmpty(equipment) && (
                            <li>
                                <span className="secondaryText">
                                    Equipment:
                                </span>{' '}
                                {equipment}
                            </li>
                        )}
                    </ul>
                    <Button
                        className="margin-top--small"
                        as="div"
                        labelPosition="right"
                    >
                        <Button icon>
                            <Icon name="heart" />
                            Favorite
                        </Button>
                        <Label as="a" basic pointing="left">
                            {numFavorites}
                        </Label>
                    </Button>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

MyPlanTodaysWorkout.propTypes = {
    onPreviewVideoClick: PropTypes.func.isRequired,
};

export default MyPlanTodaysWorkout;
